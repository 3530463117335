import { formatHour } from 'utils/dateManager';
import axios from 'config/axios';
import { defaultFilter } from '..';
import { ApiFunusRecord,  FunusApiResponse } from '../types';
import { AshesInfoResponseDto } from './types';
import { UpdateAshesDeliveryRequest } from './types';
import { RecordInfoDto } from './types';

export const record: ApiFunusRecord = {
  assignAdvisers: (params) => axios.post('/record/users', params),
  closeRecord: (id) => axios.put(`/record/${id}/close`),
  createPickup: ({ id, values }) => axios.post(`/record/${id}/pickup`, values),
  createReception: ({ id, values }) => axios.put(`/records/${id}/workorders`, {
    type: 'RECEPCION',
    record: values
  }),
  deliveryObjets: (id, params) => axios.put(`/record/${id}/personalItemsQr`, params),
  editRecord: ({ id, values }) => axios.put(`/record/${id}`, values),
  getLandingRecords: (params) => axios.post('/landing/records', params),
  getRecordById: (id) => axios.get(`/record/${id}`),
  getRecords: (params = defaultFilter): FunusApiResponse<any> => axios.post('/records', params),
  saveRecord: (params) => axios.post('/record', params),
  appointment: (id, date) => axios.put(`/records/${id}/appointment`, {
    date,
    time: !!date
      ? formatHour(date)
      : null
  }),
  services: (params) => axios.post('/records/services', params),
  delete: (id: string | number): any => axios.delete(`/records/${id}`),
  getAshesDelivery: (id: string | number): FunusApiResponse<AshesInfoResponseDto> => axios.get(`/records/${id}/ashes/delivery`),
  updateAshesDelivery: (id: string |number, payload: UpdateAshesDeliveryRequest) => axios.put(`/records/${id}/ashes/delivery`, payload),
  getInfo: (erpId: string | number): FunusApiResponse<RecordInfoDto> => axios.get(`/records/${erpId}/info`),
};
