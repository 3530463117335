/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PersonalDataDocumentDto } from "../../../models/PersonalDataDocumentDto";

/** AbortionDataDto */
export interface AbortionDataDto {
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  declarantIsMother?: boolean;
  fatherFirstSurname?: string;
  fatherName?: string;
  fatherSecondSurname?: string;
  gender?: string;
  /** @format byte */
  gestationWeeks?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  type?: string;
  updatedBy?: string;
  updatedDate?: string;
}

export enum AbortionDataDto1 {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum FunusMovementTypes {
  BOX = "BOX",
  BUNK = "BUNK",
}

export enum ArticleCategoryTypes {
  FLOWERS = "FLOWERS",
}

export enum BlocksEnum {
  CEREMONIA = "CEREMONIA",
  DEFUNCION = "DEFUNCION",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  TANATOS = "TANATOS",
  VELATORIO = "VELATORIO",
}

export enum AbortionDataDto6 {
  CIF = "CIF",
  NIE = "NIE",
  NIF = "NIF",
  PASSPORT = "PASSPORT",
}

export enum AbortionDataDto7 {
  ATAUD = "ATAUD",
  COCHE_ACOMPANAMIENTO = "COCHE_ACOMPANAMIENTO",
  COCHE_FUNEBRE = "COCHE_FUNEBRE",
  COMPLEMENTOS_VELATORIO = "COMPLEMENTOS_VELATORIO",
  ESQUELA_PRENSA = "ESQUELA_PRENSA",
  ESQUELA_RECORDATORIO = "ESQUELA_RECORDATORIO",
  FLORES = "FLORES",
  GESTORIA = "GESTORIA",
  GRABACION_STREAMING = "GRABACION_STREAMING",
  JOYAS_COLGANTES = "JOYAS_COLGANTES",
  LAPIDA = "LAPIDA",
  MUSICA = "MUSICA",
  RELICARIOS = "RELICARIOS",
  URNAS = "URNAS",
}

export enum AbortionDataDto8 {
  JUDICIAL = "JUDICIAL",
  NATURAL = "NATURAL",
}

export enum AbortionDataDto9 {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

/** Address */
export interface Address {
  city?: string;
  country?: string;
  postalCode?: string;
  province?: string;
  streetName?: string;
}

/**
 * AddressDtoReq
 * Object to contain all address data information
 */
export interface AddressDtoReq {
  /** Address city */
  city?: string;
  /** Address country */
  country?: string;
  createdBy?: string;
  createdDate?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  /** Address postalCode */
  postalCode?: string;
  /** Address province */
  province?: string;
  /** Address streetName */
  streetName?: string;
  /** Address streetNumber */
  streetNumber?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/**
 * AddressDtoRes
 * Object to contain all address data information
 */
export interface AddressDtoRes {
  /** Address city */
  city?: string;
  /** Address country */
  country?: string;
  createdBy?: string;
  createdDate?: string;
  fullAddress?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  /** Address postalCode */
  postalCode?: string;
  /** Address province */
  province?: string;
  /** Address streetName */
  streetName?: string;
  /** Address streetNumber */
  streetNumber?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** AdviceDtoReq */
export interface AdviceDtoReq {
  ceremonyServiceDto?: CeremonyServiceDtoReq;
  clients?: Client[];
  cremationServiceDto?: CremationServiceDtoReq;
  deceasedData?: DeceasedPersonalDataDtoReq;
  declarantData?: DeclarantPersonalDataDtoReq;
  depositServiceDto?: DepositServiceDtoReq;
  /** @format int32 */
  erpId?: number;
  /** @format int32 */
  idRecord?: number;
  intermentServiceDto?: IntermentServiceDtoReq;
  movementServiceDto?: MovementServiceDtoReq;
  preparationServiceDto?: PreparationServiceDtoReq;
  transferServiceDto?: TransferServiceDtoReq;
  vigilServiceDto?: VigilServiceDtoReq;
}

/** AdviceDtoRes */
export interface AdviceDtoRes {
  ceremonyServiceDto?: CeremonyServiceDtoRes;
  clients?: Client[];
  cremationServiceDto?: CremationServiceDtoRes;
  deceasedData?: DeceasedPersonalDataDtoRes;
  declarantData?: DeclarantPersonalDataDtoRes;
  depositServiceDto?: DepositServiceDtoRes;
  /** @format int32 */
  erpId?: number;
  /** @format int32 */
  idRecord?: number;
  intermentServiceDto?: IntermentServiceDtoRes;
  movementServiceDto?: MovementServiceDtoRes;
  preparationServiceDto?: PreparationServiceDtoRes;
  transferServiceDto?: TransferServiceDtoRes;
  vigilServiceDto?: VigilServiceDtoRes;
  defaultArticleDate?: string;
  defaultArticleTime?: string;
}

/** AmputationDataDto */
export interface AmputationDataDto {
  createdBy?: string;
  createdDate?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  member?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** ApplicantDataDtoReq */
export interface ApplicantDataDtoReq {
  /** Object to contain all address data information */
  address?: AddressDtoReq;
  cif?: string;
  createdBy?: string;
  createdDate?: string;
  declarant?: boolean;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  identifier?: string;
  name?: string;
  nie?: string;
  passport?: string;
  phone?: string;
  signature?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** ApplicantDataDtoRes */
export interface ApplicantDataDtoRes {
  /** Object to contain all address data information */
  address?: AddressDtoRes;
  cif?: string;
  createdBy?: string;
  createdDate?: string;
  declarant?: boolean;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  identifier?: string;
  name?: string;
  nie?: string;
  passport?: string;
  phone?: string;
  signature?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** AppointmentRequest */
export interface AppointmentRequest {
  date?: string;
  time?: string;
}

/** Article */
export interface Article {
  category?: string;
  description?: string;
  id?: string;
  price?: string;
}

/** ArticleByCategoryResponseDto */
export interface ArticleByCategoryResponseDto {
  articles?: Article[];
}

/** ArticleDetailResponseDto */
export interface ArticleDetailResponseDto {
  code?: string;
  description?: string;
  image?: string;
}

/** ArticleDto */
export interface ArticleDto {
  /** @format int32 */
  amount?: number;
  client?: string;
  code?: string;
  createdBy?: string;
  createdDate?: string;
  description?: string;
  firstComment?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  secondComment?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** ArticleInfoResponseDto */
export interface ArticleInfoResponseDto {
  channel?: ArticleInfoResponseDtoChannel;
  code?: string;
  comments?: string;
  date?: string;
  deceasedFirstSurname?: string;
  deceasedName?: string;
  deceasedSecondSurname?: string;
  dedicatory?: string;
  description?: string;
  /** @format int32 */
  erpId?: number;
  /** @format int32 */
  idArticle?: number;
  status?: ArticleInfoResponseDtoStatus;
  time?: string;
  vigilRoom?: string;
}

/** ArticleRequestDto */
export interface ArticleRequestDto {
  qr?: string;
}

/** ArticleStatusRequestDto */
export interface ArticleStatusRequestDto  {
  event?: ArticleStatusRequestDtoEvent;
  image?: string;
  articleDto? : {
    quantity?: number;
    category?: string;
    channel?: OrderLineChannel;
    client?: ClientDto;
    code?: string;
    comments?: string;
    date?: string;
    dedicatory?: string;
    erpId?: number;
    observations?: string;
    price?: number;
    supplier?: string;
    idSupplier?: string;
    time?: string;
    articleDescription?: string;
  }
}

/** ArticleSupplierResponseDto */
export interface ArticleSupplierResponseDto {
  suppliers?: Supplier[];
}

/** ArticleTrackingDto */
export interface ArticleTrackingDto {
  channel?: ArticleTrackingDtoChannel;
  city?: string;
  code?: string;
  comments?: string;
  deceadedSecondSurname?: string;
  deceasedFirstSurname?: string;
  deceasedName?: string;
  dedicatory?: string;
  /** @format int32 */
  erpId?: number;
  /** @format int32 */
  id?: number;
  location?: string;
  status?: ArticleTrackingDtoStatus;
  createdDateTime?: string;
  description?: string;
  price?: number;
  hasImage: boolean;
  articleInStock: boolean;
}

/** ArticleTrackingFilter */
export type ArticleTrackingFilter = {
  channel?: ArticleTrackingFilterChannel;
  deceasedFirstSurname?: string;
  deceasedName?: string;
  deceasedSecondSurname?: string;
  /** @format int32 */
  erpId?: number;
  status?: ArticleTrackingFilterStatus;
}

/** ArticleTrackingRequest */
export interface ArticleTrackingRequest {
  filter?: ArticleTrackingFilter[];
  page?: Page;
  sort?: Sort[];
}

/** ArticleTrackingResponse */
export interface ArticleTrackingResponse {
  list?: ArticleTrackingDto[];
  /** @format int64 */
  numberOfItems?: number;
  /** @format int32 */
  numberOfPages?: number;
}

/** AshesInfoDto */
export interface AshesInfoDto {
  comment?: string;
  date?: string;
  deliveryHandler?: string;
  destination?: string;
  receiverCif?: string;
  receiverIdentifier?: string;
  receiverName?: string;
  receiverNie?: string;
  receiverPassport?: string;
}

/** AshesInfoRequestDto */
export interface AshesInfoRequestDto {
  comment?: string;
  date?: string;
  deliveryHandler?: string;
  destination?: string;
  qr?: string;
  receiverCif?: string;
  receiverIdentifier?: string;
  receiverName?: string;
  receiverNie?: string;
  receiverPassport?: string;
}

/** Assignment */
export interface Assignment {
  comment?: string;
  /** @format int32 */
  userId?: number;
}

/** BankAccount */
export interface BankAccount {
  account?: string;
  bic?: string;
  control?: string;
  controlIban?: string;
  country?: string;
  description?: string;
  entity?: string;
  iso?: string;
  office?: string;
}

/** BaseWorkOrder */
export interface BaseWorkOrder {
  assignedUsers?: SimpleUserDto[];
  city?: string;
  country?: string;
  date?: string;
  deceased?: SimpleUserDto;
  /** @format int32 */
  erpId?: number;
  /** @format int32 */
  id?: number;
  priority?: BaseWorkOrderPriority;
  province?: string;
  type?: BaseWorkOrderType;
}

/** ByteArrayResource */
export interface ByteArrayResource {
  /** @format byte */
  byteArray?: string;
  description?: string;
  /** @format binary */
  file?: File;
  filename?: string;
  inputStream?: InputStream;
  open?: boolean;
  readable?: boolean;
  /** @format uri */
  uri?: string;
  /** @format url */
  url?: string;
}

/** CalendarDto */
export interface CalendarDto {
  items?: OccupationDto[];
}

/** Card */
export interface Card {
  assignFlag?: boolean;
  /** @format int32 */
  assignedTo?: number;
  changeFlag?: boolean;
  dateTime?: string;
  detail?: Detail;
  firstSurname?: string;
  /** @format int32 */
  id?: number;
  name?: string;
  secondSurname?: string;
}

/** Category */
export interface Category {
  code?: string;
  description?: string;
}

/** CategoryByArticleResponseDto */
export interface CategoryByArticleResponseDto {
  categories?: Category[];
}

/** CenterDto */
export interface CenterDto {
  code?: string;
  createdBy?: string;
  createdDate?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  name?: string;
  updatedBy?: string;
  updatedDate?: string;
  workshopDto?: WorkshopDto;
}

/** CeremonyServiceDtoReq */
export interface CeremonyServiceDtoReq {
  accompanimentCars?: ArticleDto[];
  agency?: ArticleDto;
  /** @format int32 */
  ceremonial?: number;
  /** Object to contain all address data information */
  ceremonyAddress?: AddressDtoReq;
  ceremonyComment?: string;
  ceremonyDate?: string;
  ceremonyTime?: string;
  createdBy?: string;
  createdDate?: string;
  flowers?: ArticleDto[];
  hearses?: ArticleDto[];
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  location?: string;
  music?: ArticleDto;
  obituariesReminders?: ArticleDto[];
  postCeremonies?: PostCeremonyDtoReq[];
  pressCommunication?: boolean;
  pressObituaries?: ArticleDto[];
  serviceType?: CeremonyServiceDtoReqServiceType;
  streaming?: ArticleDto;
  type?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** CeremonyServiceDtoRes */
export interface CeremonyServiceDtoRes {
  accompanimentCars?: ArticleDto[];
  agency?: ArticleDto;
  /** @format int32 */
  ceremonial?: number;
  /** Object to contain all address data information */
  ceremonyAddress?: AddressDtoRes;
  ceremonyComment?: string;
  ceremonyDate?: string;
  ceremonyTime?: string;
  createdBy?: string;
  createdDate?: string;
  flowers?: ArticleDto[];
  hearses?: ArticleDto[];
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  location?: string;
  music?: ArticleDto;
  obituariesReminders?: ArticleDto[];
  postCeremonies?: PostCeremonyDtoRes[];
  pressCommunication?: boolean;
  pressObituaries?: ArticleDto[];
  serviceType?: CeremonyServiceDtoResServiceType;
  streaming?: ArticleDto;
  type?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** Client */
export interface Client {
  address?: Address;
  bankAccount?: BankAccount;
  cif?: string;
  email?: string;
  identifier?: string;
  name?: string;
  nie?: string;
  passport?: string;
  paymentTerm?: string;
  phone?: string;
  primaryLanguage?: string;
  secondaryLanguage?: string;
}

/**
 * ClientDto
 * Object to contain all client data information
 */
export interface ClientDto {
  /** Client firstSurname */
  firstSurname?: string;
  /** Client identifier */
  identifier: string;
  /** Client mail */
  mail?: string;
  /** Client name */
  name?: string;
  /** Client secondSurname */
  secondSurname?: string;
}

/** ClientRequest */
export interface ClientRequest {
  cif?: string;
  identifier?: string;
  nie?: string;
  passport?: string;
}

/** ConcessionDataDtoReq */
export interface ConcessionDataDtoReq {
  beneficiary?: string;
  /** Object to contain all address data information */
  beneficiaryAddress?: AddressDtoReq;
  beneficiaryCif?: string;
  beneficiaryIdentifier?: string;
  beneficiaryNie?: string;
  beneficiaryPassport?: string;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  holder?: string;
  /** Object to contain all address data information */
  holderAddress?: AddressDtoReq;
  holderCif?: string;
  holderIdentifier?: string;
  holderNie?: string;
  holderPassport?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  temporality?: string;
  type?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** ConcessionDataDtoRes */
export interface ConcessionDataDtoRes {
  beneficiary?: string;
  /** Object to contain all address data information */
  beneficiaryAddress?: AddressDtoRes;
  beneficiaryCif?: string;
  beneficiaryIdentifier?: string;
  beneficiaryNie?: string;
  beneficiaryPassport?: string;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  holder?: string;
  /** Object to contain all address data information */
  holderAddress?: AddressDtoRes;
  holderCif?: string;
  holderIdentifier?: string;
  holderNie?: string;
  holderPassport?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  temporality?: string;
  type?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** Contact */
export interface Contact {
  email?: string;
  name?: string;
  url?: string;
}

/** ContentInfo */
export interface ContentInfo {
  encoding?: string;
  mimeType?: string;
  mimeTypeName?: string;
  /** @format int64 */
  sizeInBytes?: number;
}

/** CreateHandmadeWorkOrderDto */
export interface CreateHandmadeWorkOrderDto {
  assignedUsers?: SimpleUserDto[];
  info?: HandmadeInfo;
  priority?: CreateHandmadeWorkOrderDtoPriority;
  /** @format int32 */
  recordId?: number;
}

/** CreateWorkOrderRequest */
export interface CreateWorkOrderRequest {
  record?: RecordDtoReq;
  type?: CreateWorkOrderRequestType;
}

/** CremationServiceDtoReq */
export interface CremationServiceDtoReq {
  /** Object to contain all address data information */
  address?: AddressDtoReq;
  ashesDeliveryDate?: string;
  ashesDeliveryHandler?: string;
  ashesDestiny?: string;
  ashesReceiver?: string;
  ashesReceiverCif?: string;
  ashesReceiverIdentifier?: string;
  ashesReceiverNie?: string;
  ashesReceiverPassport?: string;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  cremationDate?: string;
  cremationTime?: string;
  crematorium?: string;
  deposit?: string;
  entryDate?: string;
  entryTime?: string;
  exitDate?: string;
  exitTime?: string;
  familyPresence?: boolean;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  jewels?: ArticleDto[];
  judicial?: boolean;
  operator?: string;
  originMortuary?: string;
  oven?: string;
  owner?: string;
  reliquaries?: ArticleDto[];
  serviceType?: CremationServiceDtoReqServiceType;
  updatedBy?: string;
  updatedDate?: string;
  urns?: ArticleDto[];
  work?: boolean;
  zincBox?: boolean;
}

/** CremationServiceDtoRes */
export interface CremationServiceDtoRes {
  /** Object to contain all address data information */
  address?: AddressDtoRes;
  ashesDeliveryDate?: string;
  ashesDeliveryHandler?: string;
  ashesDestiny?: string;
  ashesReceiver?: string;
  ashesReceiverCif?: string;
  ashesReceiverIdentifier?: string;
  ashesReceiverNie?: string;
  ashesReceiverPassport?: string;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  cremationDate?: string;
  cremationTime?: string;
  crematorium?: string;
  deposit?: string;
  entryDate?: string;
  entryTime?: string;
  exitDate?: string;
  exitTime?: string;
  familyPresence?: boolean;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  jewels?: ArticleDto[];
  judicial?: boolean;
  operator?: string;
  originMortuary?: string;
  oven?: string;
  owner?: string;
  reliquaries?: ArticleDto[];
  serviceType?: CremationServiceDtoResServiceType;
  updatedBy?: string;
  updatedDate?: string;
  urns?: ArticleDto[];
  work?: boolean;
  zincBox?: boolean;
}

/** Dashboard */
export interface Dashboard {
  completed?: Card[];
  introduced?: Card[];
  pending?: Card[];
}

/** DashboardResponse */
export interface DashboardResponse {
  inProgress?: BaseWorkOrder[];
  pending?: BaseWorkOrder[];
}

/** DashboardUpdate */
export interface DashboardUpdate {
  comment?: string;
}

/** DeathDataDtoReq */
export interface DeathDataDtoReq {
  /** Object to contain all address data information */
  address?: AddressDtoReq;
  approximatedDate?: string;
  atHome?: boolean;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  deathCause?: string;
  deathDate?: string;
  deathTime?: string;
  deathType?: string;
  destinationCenter?: string;
  diseases?: string;
  doctor?: string;
  errands?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  location?: string;
  medicalCertificate?: boolean;
  /** Object to contain all address data information */
  pickupAddress?: AddressDtoReq;
  trialCourt?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** DeathDataDtoRes */
export interface DeathDataDtoRes {
  /** Object to contain all address data information */
  address?: AddressDtoRes;
  approximatedDate?: string;
  atHome?: boolean;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  deathCause?: string;
  deathDate?: string;
  deathTime?: string;
  deathType?: string;
  destinationCenter?: string;
  diseases?: string;
  doctor?: string;
  errands?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  location?: string;
  medicalCertificate?: boolean;
  /** Object to contain all address data information */
  pickupAddress?: AddressDtoRes;
  trialCourt?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** DeathFilter */
export interface DeathFilter {
  deathDate?: string;
}

/** DeceasedPersonalDataDto */
export interface DeceasedPersonalDataDto {
  /** @format int32 */
  age?: number;
  /** Object to contain all address data information */
  birthAddress?: AddressDtoRes;
  birthday?: string;
  childrenNames?: string;
  cif?: string;
  coffinQr?: string;
  createdBy?: string;
  createdDate?: string;
  /** Object to contain all address data information */
  deceasedAddress?: AddressDtoRes;
  fatherName?: string;
  firstSurname?: string;
  fullName?: string;
  gender?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  identifier?: string;
  maritalStatus?: string;
  motherName?: string;
  name?: string;
  nationality?: string;
  nickname?: string;
  nie?: string;
  partnerName?: string;
  passport?: string;
  phone?: string;
  qr?: string;
  secondSurname?: string;
  updatedBy?: string;
  updatedDate?: string;
  urnQr?: string;
}

/** DeceasedPersonalDataDtoReq */
export interface DeceasedPersonalDataDtoReq {
  /** @format int32 */
  age?: number;
  /** Object to contain all address data information */
  birthAddress?: AddressDtoReq;
  birthday?: string;
  childrenNames?: string;
  cif?: string;
  coffinQr?: string;
  createdBy?: string;
  createdDate?: string;
  /** Object to contain all address data information */
  deceasedAddress?: AddressDtoReq;
  fatherName?: string;
  firstSurname?: string;
  gender?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  identifier?: string;
  maritalStatus?: string;
  motherName?: string;
  name?: string;
  nationality?: string;
  nickname?: string;
  nie?: string;
  partnerName?: string;
  passport?: string;
  phone?: string;
  qr?: string;
  secondSurname?: string;
  updatedBy?: string;
  updatedDate?: string;
  urnQr?: string;
}

/** DeceasedPersonalDataDtoRes */
export interface DeceasedPersonalDataDtoRes {
  /** @format int32 */
  age?: number;
  /** Object to contain all address data information */
  birthAddress?: AddressDtoRes;
  birthday?: string;
  childrenNames?: string;
  cif?: string;
  coffinQr?: string;
  createdBy?: string;
  createdDate?: string;
  /** Object to contain all address data information */
  deceasedAddress?: AddressDtoRes;
  fatherName?: string;
  firstSurname?: string;
  fullName?: string;
  gender?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  identifier?: string;
  maritalStatus?: string;
  motherName?: string;
  name?: string;
  nationality?: string;
  nickname?: string;
  nie?: string;
  partnerName?: string;
  passport?: string;
  phone?: string;
  qr?: string;
  secondSurname?: string;
  updatedBy?: string;
  updatedDate?: string;
  urnQr?: string;
}

/** DeceasedPersonalDataFilter */
export interface DeceasedPersonalDataFilter {
  firstSurname?: string;
  name?: string;
  secondSurname?: string;
}

/** DeclarantPersonalDataDtoReq */
export interface DeclarantPersonalDataDtoReq {
  /** Object to contain all address data information */
  address?: AddressDtoReq;
  cif?: string;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  deceasedKinship?: string;
  email?: string;
  firstSurname?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  identifier?: string;
  name?: string;
  nie?: string;
  passport?: string;
  phone?: string;
  secondSurname?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** DeclarantPersonalDataDtoRes */
export interface DeclarantPersonalDataDtoRes {
  /** Object to contain all address data information */
  address?: AddressDtoRes;
  cif?: string;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  deceasedKinship?: string;
  email?: string;
  firstSurname?: string;
  fullName?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  identifier?: string;
  name?: string;
  nie?: string;
  passport?: string;
  phone?: string;
  secondSurname?: string;
  updatedBy?: string;
  updatedDate?: string;
}

export interface DeleteDocumentUsingDeleteParams {
  /**
   * permanent
   * @default "1"
   */
  permanent?: boolean;
  /** nodeId */
  nodeId: string;
}

/** DepositDto */
export interface DepositDto {
  /** Object to contain all address data information */
  address?: AddressDtoReq;
  comment?: string;
  deposit?: string;
  entryDate?: string;
  entryTime?: string;
  exitDate?: string;
  exitTime?: string;
}

/** DepositServiceDtoReq */
export interface DepositServiceDtoReq {
  /** Object to contain all address data information */
  address?: AddressDtoReq;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  deposit?: string;
  entryDate?: string;
  entryTime?: string;
  exitDate?: string;
  exitTime?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  serviceType?: DepositServiceDtoReqServiceType;
  updatedBy?: string;
  updatedDate?: string;
}

/** DepositServiceDtoRes */
export interface DepositServiceDtoRes {
  /** Object to contain all address data information */
  address?: AddressDtoRes;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  deposit?: string;
  entryDate?: string;
  entryTime?: string;
  exitDate?: string;
  exitTime?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  serviceType?: DepositServiceDtoResServiceType;
  updatedBy?: string;
  updatedDate?: string;
}

/** Detail */
export interface Detail {
  advisorComment?: string;
  center?: string;
  coordinatorComment?: string;
  creator?: UserDto;
  email?: string;
  phone?: string;
  subject?: string;
}

/** DisagreementRequestDto */
export interface DisagreementRequestDto {
  detail?: string;
  image?: string;
  replacement?: boolean;
  type?: DisagreementRequestDtoType;
}

/** DisagreementTypeResponseDto */
export interface DisagreementTypeResponseDto {
  types?: DisagreementTypeResponseDtoTypes[];
}

/** DocumentInfo */
export interface DocumentInfo {
  id?: string;
  name?: string;
  type?: string;
}

/** EnquiryDtoReq */
export interface EnquiryDtoReq {
  advisor?: UserDto;
  advisorComment?: string;
  center?: string;
  clientName?: string;
  comment?: string;
  coordinatorComment?: string;
  createdBy?: string;
  createdDate?: string;
  creator?: UserDto;
  email?: string;
  firstSurname?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  phone?: string;
  records?: RecordDtoReq[];
  secondSurname?: string;
  status?: EnquiryDtoReqStatus;
  updatedBy?: string;
  updatedDate?: string;
}

/** EnquiryDtoRes */
export interface EnquiryDtoRes {
  advisor?: UserDto;
  advisorComment?: string;
  center?: string;
  clientName?: string;
  comment?: string;
  coordinatorComment?: string;
  createdBy?: string;
  createdDate?: string;
  creator?: UserDto;
  email?: string;
  firstSurname?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  phone?: string;
  records?: RecordDtoRes[];
  secondSurname?: string;
  status?: EnquiryDtoResStatus;
  updatedBy?: string;
  updatedDate?: string;
}

/** EnquiryFilter */
export interface EnquiryFilter {
  clientName?: string;
  comment?: string;
  createdDate?: string;
  email?: string;
  firstSurname?: string;
  phone?: string;
  secondSurname?: string;
}

/** EnquiryPaginatedRequest */
export interface EnquiryPaginatedRequest {
  filter?: EnquiryFilter[];
  page?: Page;
  sort?: Sort[];
}

/** EnquiryPaginatedResponse */
export interface EnquiryPaginatedResponse {
  list?: EnquiryDtoRes[];
  /** @format int64 */
  numberOfItems?: number;
  /** @format int32 */
  numberOfPages?: number;
}

/** EntryChannelResponseDto */
export interface EntryChannelResponseDto {
  types?: OrderLineChannel[];
}

export interface FindWorkOrdersUsingGetParams {
  /** type */
  type?: FindWorkOrdersUsingGetParams;
  /** workshop */
  workshop?: string;
}

/** FlightDataDto */
export interface FlightDataDto {
  agency?: string;
  arrivalDate?: string;
  arrivalTime?: string;
  company?: string;
  connectionNumber?: string;
  createdBy?: string;
  createdDate?: string;
  deliveryDate?: string;
  deliveryTime?: string;
  departureAirport?: string;
  departureDate?: string;
  departureTime?: string;
  destinationAirport?: string;
  flightNumber?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  updatedBy?: string;
  updatedDate?: string;
}

export interface GetCalendarUsingGetParams {
  /** day */
  day?: string;
  /** month */
  month: string;
  /** services */
  services: GetCalendarUsingGetParams;
  /** year */
  year: string;
}

export interface GetPreneedRecordWithErpIdUsingGetParams {
  /**
   * erpid
   * @format int32
   */
  erpid?: number;
  /** document */
  document: string;
  /** document-type */
  documentType: GetPreneedRecordWithErpIdUsingGetParams;
}

/** HandmadeInfo */
export interface HandmadeInfo {
  description?: string;
  vehicle?: string;
}

/** HandmadeWorkOrderDto */
export interface HandmadeWorkOrderDto {
  /** @uniqueItems true */
  assignedUsers?: SimpleUserDto[];
  coordinatorComment?: string;
  createdBy?: string;
  createdDate?: string;
  deceasedFirstSurname?: string;
  deceasedName?: string;
  deceasedSecondSurname?: string;
  description?: string;
  driver?: string;
  dueDate?: string;
  /** @format int32 */
  erpId?: number;
  /** @format int32 */
  estimatedTime?: number;
  finishDate?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  info?: HandmadeInfo;
  infoQr?: InfoQr;
  operatorComment?: string;
  priority?: HandmadeWorkOrderDtoPriority;
  /** @format int32 */
  recordId?: number;
  startDate?: string;
  status?: HandmadeWorkOrderDtoStatus;
  type?: HandmadeWorkOrderDtoType;
  updatedBy?: string;
  updatedDate?: string;
  vehicle?: string;
}

/** HealthResponse */
export interface HealthResponse {
  database?: HealthResponseDatabase;
  description?: string;
}

/** Info */
export interface Info {
  coffinQr?: string;
  coordinatorComment?: string;
  deceasedQr?: string;
  deposit?: DepositDto;
  operatorComment?: string;
  personalItemsQr?: string;
  urnQr?: string;
  vigil?: Vigil;
}

/** InfoQr */
export interface InfoQr {
  coffinQr?: boolean;
  deceasedQr?: boolean;
  personalItemsQr?: boolean;
  urnQr?: boolean;
}

/** InputStream */
export type InputStream = object;

/** InsuranceDataDto */
export interface InsuranceDataDto {
  agent?: string;
  amount?: string;
  companyDelegation?: string;
  companyName?: string;
  createdBy?: string;
  createdDate?: string;
  hasPolicy?: boolean;
  /** @format int32 */
  id?: number;
  idPaymentLocation?: string;
  /** @format int32 */
  idVersion?: number;
  /** @format int32 */
  maxCoverage?: number;
  personalObjects?: string;
  policyNumber?: string;
  renounce?: boolean;
  sinister?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** IntermentServiceDtoReq */
export interface IntermentServiceDtoReq {
  ashes?: boolean;
  cemetery?: string;
  cemeteryAshes?: boolean;
  cemeteryCoffin?: boolean;
  concession?: ConcessionDataDtoReq;
  createdBy?: string;
  createdDate?: string;
  familiarPresentPreparation?: boolean;
  flowers?: ArticleDto[];
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  /** Object to contain all address data information */
  intermentAddress?: AddressDtoReq;
  intermentComment?: string;
  intermentDate?: string;
  intermentTime?: string;
  judicial?: boolean;
  niche?: string;
  nicheNumber?: string;
  nicheSection?: string;
  /** @format int32 */
  nicheTier?: number;
  preparationDate?: string;
  preparationTime?: string;
  serviceType?: IntermentServiceDtoReqServiceType;
  takeMeasurementsPhoto?: boolean;
  tombstones?: ArticleDto[];
  updatedBy?: string;
  updatedDate?: string;
  zincBox?: boolean;
}

/** IntermentServiceDtoRes */
export interface IntermentServiceDtoRes {
  ashes?: boolean;
  cemetery?: string;
  cemeteryAshes?: boolean;
  cemeteryCoffin?: boolean;
  concession?: ConcessionDataDtoRes;
  createdBy?: string;
  createdDate?: string;
  familiarPresentPreparation?: boolean;
  flowers?: ArticleDto[];
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  /** Object to contain all address data information */
  intermentAddress?: AddressDtoRes;
  intermentComment?: string;
  intermentDate?: string;
  intermentTime?: string;
  judicial?: boolean;
  niche?: string;
  nicheNumber?: string;
  nicheSection?: string;
  /** @format int32 */
  nicheTier?: number;
  preparationDate?: string;
  preparationTime?: string;
  serviceType?: IntermentServiceDtoResServiceType;
  takeMeasurementsPhoto?: boolean;
  tombstones?: ArticleDto[];
  updatedBy?: string;
  updatedDate?: string;
  zincBox?: boolean;
}

/** LandingFilter */
export interface LandingFilter {
  negotiator?: string;
}

/** License */
export interface License {
  name?: string;
  url?: string;
}

/** LocalDto */
export interface LocalDto {
  description?: string;
  localId?: string;
}

/** LoginDto */
export interface LoginDto {
  /** @format int32 */
  id?: number;
  image?: string;
  name?: string;
  role?: RoleDto;
  surnames?: string;
  token?: string;
}

/** LoginRequest */
export interface LoginRequest {
  password?: string;
  username?: string;
}

/** MasterDataCityDto */
export interface MasterDataCityDto {
  code?: string;
  countryCode?: string;
  countryDescription?: string;
  description?: string;
  postalCode?: string;
  provinceCode?: string;
  provinceDescription?: string;
}

/** MasterDataDeathLocationDto */
export interface MasterDataDeathLocationDto {
  address?: string;
  city?: string;
  code?: string;
  description?: string;
  postalCode?: string;
}

/** MasterDataDto */
export interface MasterDataDto {
  code?: string;
  description?: string;
}

/** MasterDataProvinceDto */
export interface MasterDataProvinceDto {
  code?: string;
  countryCode?: string;
  countryDescription?: string;
  description?: string;
}

/** MasterDataVigilLocationDto */
export interface MasterDataVigilLocationDto {
  city?: string;
  code?: string;
  description?: string;
  location?: string;
}

/** MasterDataWithCityDto */
export interface MasterDataWithCityDto {
  city?: string;
  code?: string;
  description?: string;
}

/** MasterDataWithLocalsDto */
export interface MasterDataWithLocalsDto {
  code?: string;
  description?: string;
  locals?: LocalDto[];
}

/** MasterServiceDto */
export interface MasterServiceDto {
  /** @format int32 */
  sorting?: number;
  type?: MasterServiceDtoType;
  workOrders?: MasterServiceDtoWorkOrders[];
}

/** MasterWorkOrderDto */
export interface MasterWorkOrderDto {
  service?: MasterWorkOrderDtoService;
  type?: MasterWorkOrderDtoType;
}

/** MovementServiceDtoReq */
export interface MovementServiceDtoReq {
  /** Object to contain all address data information */
  address?: AddressDtoReq;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  date?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  serviceType?: MovementServiceDtoReqServiceType;
  time?: string;
  type?: MovementServiceDtoReqType;
  updatedBy?: string;
  updatedDate?: string;
  vigilPlace?: string;
}

/** MovementServiceDtoRes */
export interface MovementServiceDtoRes {
  /** Object to contain all address data information */
  address?: AddressDtoRes;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  date?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  serviceType?: MovementServiceDtoResServiceType;
  time?: string;
  type?: MovementServiceDtoResType;
  updatedBy?: string;
  updatedDate?: string;
  vigilPlace?: string;
}

/** NewPasswordDto */
export interface NewPasswordDto {
  newPassword?: string;
}

/** Node */
export interface Node {
  allowableOperations?: string[];
  aspectNames?: string[];
  content?: ContentInfo;
  createdAt?: string;
  createdByUser?: UserInfo;
  id?: string;
  isFavorite?: boolean;
  isFile?: boolean;
  isFolder?: boolean;
  isLink?: boolean;
  isLocked?: boolean;
  modifiedAt?: string;
  modifiedByUser?: UserInfo;
  name?: string;
  nodeType?: string;
  parentId?: string;
  path?: PathInfo;
  permissions?: PermissionInfo;
  properties?: object;
}

/** NodeEntry */
export interface NodeEntry {
  entry?: Node;
}

/** NotificationDto */
export interface NotificationDto {
  content?: string;
  title?: string;
}

/** OccupationDto */
export interface OccupationDto {
  date?: string;
  /** @format int32 */
  erpId?: number;
  /** @format int32 */
  id?: number;
  time?: string;
  type?: string;
  /** @uniqueItems true */
  users?: UserDto[];
}

/**
 * Order line
 * Object to contain all order line data information
 */
export interface OrderLine {
  amount: number;
  /** Order line's category */
  category?: string;
  /** Incoming channel */
  channel: OrderLineChannel;
  /** Object to contain all client data information */
  client?: ClientDto;
  /** Order line's code */
  code?: string;
  articleDescription?: string;
  /** Comments */
  comments?: string;
  /** Order line's date with format: yyyy-MM-dd */
  date?: Date;
  /** Order line's dedicatory */
  dedicatory?: string;
  /**
   * EKON erpid
   * @format int32
   */
  erpId: number;
  /** Order line's observation */
  observations?: string;
  /**
   * Order line's price
   * @format double
   */
  price?: number;
  /** Order line's supplier */
  supplier: string;
  idSupplier?: string;
  /** Order line's time with format: HH:mm */
  time?: Date;
}

/** Page */
export interface Page {
  /** @format int32 */
  index?: number;
  /** @format int32 */
  size?: number;
}

/** PasswordDto */
export interface PasswordDto {
  currentPassword?: string;
  newPassword?: string;
}

/** PathElement */
export interface PathElement {
  aspectNames?: string[];
  id?: string;
  name?: string;
  nodeType?: string;
}

/** PathInfo */
export interface PathInfo {
  elements?: PathElement[];
  isComplete?: boolean;
  name?: string;
}

/** PermissionDto */
export interface PermissionDto {
  createdBy?: string;
  createdDate?: string;
  description?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  name?: string;
  selected?: boolean;
  updatedBy?: string;
  updatedDate?: string;
}

/** PermissionElement */
export interface PermissionElement {
  accessStatus?: PermissionElementAccessStatus;
  authorityId?: string;
  name?: string;
}

/** PermissionGroupDto */
export interface PermissionGroupDto {
  createdBy?: string;
  createdDate?: string;
  description?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  permissionDtos?: PermissionDto[];
  updatedBy?: string;
  updatedDate?: string;
}

/** PermissionInfo */
export interface PermissionInfo {
  inherited?: PermissionElement[];
  isInheritanceEnabled?: boolean;
  locallySet?: PermissionElement[];
  settable?: string[];
}

/** PersonDto */
export interface PersonDto {
  firstSurname?: string;
  name?: string;
  secondSurname?: string;
}

/** PlaneTransportDataDto */
export interface PlaneTransportDataDto {
  createdBy?: string;
  createdDate?: string;
  flights?: FlightDataDto[];
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  measures?: string;
  updatedBy?: string;
  updatedDate?: string;
  weight?: string;
}

/** PostCeremonyDtoReq */
export interface PostCeremonyDtoReq {
  /** Object to contain all address data information */
  address?: AddressDtoReq;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  date?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  location?: string;
  time?: string;
  type?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** PostCeremonyDtoRes */
export interface PostCeremonyDtoRes {
  /** Object to contain all address data information */
  address?: AddressDtoRes;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  date?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  location?: string;
  time?: string;
  type?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** PreparationServiceDtoReq */
export interface PreparationServiceDtoReq {
  aconditioning?: boolean;
  agency?: ArticleDto;
  applicant?: ApplicantDataDtoReq;
  clothing?: boolean;
  clothingComment?: string;
  coffin?: boolean;
  coffinArticle?: ArticleDto;
  coffinPreparationDetail?: string;
  comment?: string;
  cover?: boolean;
  coverComment?: string;
  createdBy?: string;
  createdDate?: string;
  creu?: boolean;
  creuComment?: string;
  deceasedLocation?: string;
  finalDestination?: string;
  hairstyle?: boolean;
  hairstyleComment?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  makeup?: boolean;
  makeupComment?: string;
  pacemaker?: boolean;
  personalItems?: string;
  preparation?: string;
  sanitaryPracticeAuthorization?: boolean;
  sanitaryPracticePlace?: string;
  sanitaryPractices?: SanitaryPracticeDataDtoReq[];
  serviceType?: PreparationServiceDtoReqServiceType;
  shaved?: boolean;
  shavedComment?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** PreparationServiceDtoRes */
export interface PreparationServiceDtoRes {
  aconditioning?: boolean;
  agency?: ArticleDto;
  applicant?: ApplicantDataDtoRes;
  clothing?: boolean;
  clothingComment?: string;
  coffin?: boolean;
  coffinArticle?: ArticleDto;
  coffinPreparationDetail?: string;
  comment?: string;
  cover?: boolean;
  coverComment?: string;
  createdBy?: string;
  createdDate?: string;
  creu?: boolean;
  creuComment?: string;
  deceasedLocation?: string;
  finalDestination?: string;
  hairstyle?: boolean;
  hairstyleComment?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  makeup?: boolean;
  makeupComment?: string;
  pacemaker?: boolean;
  personalItems?: string;
  preparation?: string;
  sanitaryPracticeAuthorization?: boolean;
  sanitaryPracticePlace?: string;
  sanitaryPractices?: SanitaryPracticeDataDtoRes[];
  serviceType?: PreparationServiceDtoResServiceType;
  shaved?: boolean;
  shavedComment?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** ProfileUserDto */
export interface ProfileUserDto {
  email?: string;
  firstSurname?: string;
  /** @format int32 */
  id?: number;
  image?: string;
  name?: string;
  secondSurname?: string;
}

/** ReceptionDataDto */
export interface ReceptionDataDto {
  createdBy?: string;
  createdDate?: string;
  entryDate?: string;
  entryTime?: string;
  funeraryOrigin?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  observations?: string;
  operatorComment?: string;
  receptionType?: ReceptionDataDtoReceptionType;
  updatedBy?: string;
  updatedDate?: string;
}

/** RecordDtoReq */
export interface RecordDtoReq {
  abortionData?: AbortionDataDto;
  ageConfidential?: boolean;
  amputationData?: AmputationDataDto;
  appointmentDate?: string;
  appointmentDateTime?: string;
  appointmentTime?: string;
  canCreateReception?: boolean;
  center?: CenterDto;
  collectionComment?: string;
  comment?: string;
  companyName?: string;
  concessionData?: ConcessionDataDtoReq;
  createdBy?: string;
  createdDate?: string;
  creator?: SimpleUserDto;
  deathData?: DeathDataDtoReq;
  deceasedData?: DeceasedPersonalDataDtoReq;
  deceasedDataDocumentDto?: PersonalDataDocumentDto;
  declarantData?: DeclarantPersonalDataDtoReq;
  declarantDataDocumentDto?: PersonalDataDocumentDto;
  documents?: DocumentInfo[];
  /** @format int32 */
  erpId?: number;
  firstNegociator?: string;
  hasPickUp?: boolean;
  hasReception?: boolean;
  hasWorkOrder?: boolean;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  insuranceData?: InsuranceDataDto;
  insurancePolicyAvailable?: boolean;
  negotiators?: SimpleUserDto[];
  personalItems?: string;
  personalItemsQr?: string;
  publishCeremony?: boolean;
  publishVigil?: boolean;
  receptionData?: ReceptionDataDto;
  secondNegociator?: string;
  sendByEmail?: boolean;
  services?: ServiceDtoReq[];
  signature?: string;
  state?: RecordDtoReqState;
  supplementalDeclarants?: DeclarantPersonalDataDtoReq[];
  type?: string;
  updatedBy?: string;
  updatedDate?: string;
  workorders?: WorkOrderDto[];
}

/** RecordDtoRes */
export interface RecordDtoRes {
  abortionData?: AbortionDataDto;
  ageConfidential?: boolean;
  amputationData?: AmputationDataDto;
  appointmentDate?: string;
  appointmentDateTime?: string;
  appointmentTime?: string;
  canCreateReception?: boolean;
  center?: CenterDto;
  collectionComment?: string;
  comment?: string;
  companyName?: string;
  concessionData?: ConcessionDataDtoRes;
  createdBy?: string;
  createdDate?: string;
  creator?: SimpleUserDto;
  deathData?: DeathDataDtoRes;
  deceasedData?: DeceasedPersonalDataDtoRes;
  deceasedDataDocumentDto?: PersonalDataDocumentDto;
  declarantData?: DeclarantPersonalDataDtoRes;
  declarantDataDocumentDto?: PersonalDataDocumentDto;
  documents?: DocumentInfo[];
  /** @format int32 */
  erpId?: number;
  firstNegociator?: string;
  hasPickUp?: boolean;
  hasReception?: boolean;
  hasWorkOrder?: boolean;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  insuranceData?: InsuranceDataDto;
  insurancePolicyAvailable?: boolean;
  negotiators?: SimpleUserDto[];
  personalItems?: string;
  personalItemsQr?: string;
  publishCeremony?: boolean;
  publishVigil?: boolean;
  receptionData?: ReceptionDataDto;
  secondNegociator?: string;
  sendByEmail?: boolean;
  services?: ServiceDtoRes[];
  signature?: string;
  state?: RecordDtoResState;
  supplementalDeclarants?: DeclarantPersonalDataDtoRes[];
  type?: string;
  updatedBy?: string;
  updatedDate?: string;
  workorders?: WorkOrderDto[];
}

/** RecordFilter */
export interface RecordFilter {
  comment?: string;
  companyName?: string;
  death?: DeathFilter;
  deceased?: DeceasedPersonalDataFilter;
  /** @format int32 */
  erpId?: number;
  /** @format int64 */
  id?: number;
  insurancePolicyAvailable?: boolean;
  personalItems?: string;
  state?: RecordFilterState;
  type?: string;
}

/** RecordInformationDto */
export interface RecordInformationDto {
  appointmentDateTime?: string;
  ceremony?: ServiceInformationDto;
  collectionDateTime?: string;
  cremation?: ServiceInformationDto;
  deathType?: string;
  /** @format int32 */
  erpId?: number;
  interment?: ServiceInformationDto;
  person?: PersonDto;
  preparationStatus?: RecordInformationDtoPreparationStatus;
  type?: RecordInformationDtoType;
  vigil?: ServiceInformationDto;
}

/** RecordInformationFilter */
export interface RecordInformationFilter {
  date?: string;
  deceasedFirstSurname?: string;
  deceasedName?: string;
  deceasedSecondSurname?: string;
  /** @format int32 */
  erpId?: number;
  type?: RecordInformationFilterType;
}

/** RecordInformationPaginatedRequest */
export interface RecordInformationPaginatedRequest {
  filter?: RecordInformationFilter[];
  page?: Page;
  sort?: Sort[];
}

/** RecordInformationPaginatedResponse */
export interface RecordInformationPaginatedResponse {
  list?: RecordInformationDto[];
  /** @format int64 */
  numberOfItems?: number;
  /** @format int32 */
  numberOfPages?: number;
}

/** RecordPaginatedRequest */
export interface RecordPaginatedRequest {
  filter?: RecordFilter[];
  page?: Page;
  sort?: Sort[];
}

/** RecordPersonalItemsQrDto */
export interface RecordPersonalItemsQrDto {
  personalItemsQr?: string;
}

/** RecordQrDetailDto */
export interface RecordQrDetailDto {
  coffinQr?: string;
  createdBy?: string;
  createdDate?: string;
  deathDate?: string;
  /** @format int32 */
  erpId?: number;
  firstSurname?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  name?: string;
  personalItemsQr?: string;
  qr?: string;
  secondSurname?: string;
  updatedBy?: string;
  updatedDate?: string;
  urnQr?: string;
}

/** RecordQrDetailRequest */
export interface RecordQrDetailRequest {
  filter?: RecordFilter[];
  page?: Page;
  sort?: Sort[];
}

/** RecordQrDetailResponse */
export interface RecordQrDetailResponse {
  list?: RecordQrDetailDto[];
  /** @format int64 */
  numberOfItems?: number;
  /** @format int32 */
  numberOfPages?: number;
}

/** RecordUserRequest */
export interface RecordUserRequest {
  /** @format int32 */
  recordId?: number;
  userIds?: number[];
}

/** RecordWorkOrderExcludedStatusPaginatedRequest */
export interface RecordWorkOrderExcludedStatusPaginatedRequest {
  filter?: RecordFilter[];
  page?: Page;
  sort?: Sort[];
  workOrderExcludedStatus?: RecordWorkOrderExcludedStatusPaginatedRequestWorkOrderExcludedStatus;
}

export interface RecoverPasswordUsingGetParams {
  /** username */
  username: string;
}

/** RoadTransportDataDto */
export interface RoadTransportDataDto {
  borderCrossing?: string;
  createdBy?: string;
  createdDate?: string;
  destination?: string;
  estimatedArrivalDate?: string;
  estimatedArrivalTime?: string;
  exitDate?: string;
  exitTime?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  updatedBy?: string;
  updatedDate?: string;
}

/** RoleDto */
export interface RoleDto {
  createdBy?: string;
  createdDate?: string;
  description?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  name?: string;
  permissions?: PermissionDto[];
  readOnly?: boolean;
  updatedBy?: string;
  updatedDate?: string;
}

/** RoleFilter */
export interface RoleFilter {
  description?: string;
  name?: string;
}

/** RolePaginatedRequest */
export interface RolePaginatedRequest {
  filter?: RoleFilter[];
  page?: Page;
  sort?: Sort[];
}

/** RolePaginatedResponse */
export interface RolePaginatedResponse {
  list?: RoleDto[];
  /** @format int64 */
  numberOfItems?: number;
  /** @format int32 */
  numberOfPages?: number;
}

/** SanitaryPracticeDataDtoReq */
export interface SanitaryPracticeDataDtoReq {
  /** Object to contain all address data information */
  address?: AddressDtoReq;
  atHome?: boolean;
  createdBy?: string;
  createdDate?: string;
  endDate?: string;
  endTime?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  location?: string;
  startDate?: string;
  startTime?: string;
  thanatologist?: string;
  thanatopractor?: string;
  type?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** SanitaryPracticeDataDtoRes */
export interface SanitaryPracticeDataDtoRes {
  /** Object to contain all address data information */
  address?: AddressDtoRes;
  atHome?: boolean;
  createdBy?: string;
  createdDate?: string;
  endDate?: string;
  endTime?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  location?: string;
  startDate?: string;
  startTime?: string;
  thanatologist?: string;
  thanatopractor?: string;
  type?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** ServiceDtoReq */
export interface ServiceDtoReq {
  createdBy?: string;
  createdDate?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  serviceType?: ServiceDtoReqServiceType;
  updatedBy?: string;
  updatedDate?: string;
}

/** ServiceDtoRes */
export interface ServiceDtoRes {
  createdBy?: string;
  createdDate?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  serviceType?: ServiceDtoResServiceType;
  updatedBy?: string;
  updatedDate?: string;
}

/** ServiceInformationDto */
export interface ServiceInformationDto {
  city?: string;
  country?: string;
  date?: string;
  location?: string;
  province?: string;
  time?: string;
}

/** SignatureRequest */
export interface SignatureRequest {
  signature?: string;
}

/** SimpleRecordDto */
export interface SimpleRecordDto {
  deceasedData?: DeceasedPersonalDataDto;
  /** @format int32 */
  erpId?: number;
}

/** SimpleRecordPaginatedRequest */
export interface SimpleRecordPaginatedRequest {
  filter?: LandingFilter[];
  page?: Page;
  sort?: Sort[];
}

/** SimpleRecordPaginatedResponse */
export interface SimpleRecordPaginatedResponse {
  list?: SimpleRecordDto[];
  /** @format int64 */
  numberOfItems?: number;
  /** @format int32 */
  numberOfPages?: number;
}

/** SimpleUserDto */
export interface SimpleUserDto {
  email?: string;
  firstSurname?: string;
  /** @format int32 */
  id?: number;
  name?: string;
  secondSurname?: string;
}

/** SimpleUserRequest */
export interface SimpleUserRequest {
  permissionNames?: SimpleUserRequestPermissionNames[];
}

/** Sort */
export interface Sort {
  descending?: boolean;
  name?: string;
}

/** Supplier */
export interface Supplier {
  email?: string;
  id?: string;
  name?: string;
  selected?: boolean;
}

/** T */
export interface T {
  /** @format int32 */
  maxLineLength?: number;
  name?: string;
  /** @format byte */
  paddingByte?: string;
  paddingChar?: string;
}

/** TelevisionDto */
export interface TelevisionDto {
  createdBy?: string;
  createdDate?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  ip?: string;
  name?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** TokenDto */
export interface TokenDto {
  token?: string;
}

/** TransferServiceDtoReq */
export interface TransferServiceDtoReq {
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  planeTransport?: PlaneTransportDataDto;
  /** Object to contain all address data information */
  recipientAddress?: AddressDtoReq;
  recipientComment?: string;
  recipientEmail?: string;
  recipientName?: string;
  recipientPhone?: string;
  roadTransport?: RoadTransportDataDto;
  serviceType?: TransferServiceDtoReqServiceType;
  type?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** TransferServiceDtoRes */
export interface TransferServiceDtoRes {
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  planeTransport?: PlaneTransportDataDto;
  /** Object to contain all address data information */
  recipientAddress?: AddressDtoRes;
  recipientComment?: string;
  recipientEmail?: string;
  recipientName?: string;
  recipientPhone?: string;
  roadTransport?: RoadTransportDataDto;
  serviceType?: TransferServiceDtoResServiceType;
  type?: string;
  updatedBy?: string;
  updatedDate?: string;
}

/** UpdateUserDto */
export interface UpdateUserDto {
  image?: string;
}

/** UserDto */
export interface UserDto {
  active?: boolean;
  createdBy?: string;
  createdDate?: string;
  email?: string;
  firstSurname?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  /** @format int32 */
  idrole?: number;
  image?: string;
  name?: string;
  secondSurname?: string;
  updatedBy?: string;
  updatedDate?: string;
  username?: string;
  workplace?: string;
}

/** UserFilter */
export interface UserFilter {
  email?: string;
  firstSurname?: string;
  name?: string;
  secondSurname?: string;
  username?: string;
}

/** UserInfo */
export interface UserInfo {
  displayName?: string;
  id?: string;
}

/** UserPaginatedRequest */
export interface UserPaginatedRequest {
  filter?: UserFilter[];
  page?: Page;
  sort?: Sort[];
}

/** UserPaginatedResponse */
export interface UserPaginatedResponse {
  list?: UserDto[];
  /** @format int64 */
  numberOfItems?: number;
  /** @format int32 */
  numberOfPages?: number;
}

/** Vigil */
export interface Vigil {
  /** Object to contain all address data information */
  address?: AddressDtoReq;
  atHome?: boolean;
  comment?: string;
  /** @format date */
  date?: string;
  location?: string;
  place?: string;
  room?: string;
  time?: string;
}

/** VigilServiceDtoReq */
export interface VigilServiceDtoReq {
  accompanimentCars?: ArticleDto[];
  /** Object to contain all address data information */
  address?: AddressDtoReq;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  entryDate?: string;
  entryTime?: string;
  exitDate?: string;
  exitTime?: string;
  externalNumber?: string;
  flowers?: ArticleDto[];
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  location?: string;
  music?: ArticleDto;
  obituariesReminders?: ArticleDto[];
  pressObituaries?: ArticleDto[];
  serviceType?: VigilServiceDtoReqServiceType;
  updatedBy?: string;
  updatedDate?: string;
  vigilAtHome?: boolean;
  vigilComplements?: ArticleDto[];
  vigilPlace?: string;
  vigilRoom?: string;
}

/** VigilServiceDtoRes */
export interface VigilServiceDtoRes {
  accompanimentCars?: ArticleDto[];
  /** Object to contain all address data information */
  address?: AddressDtoRes;
  comment?: string;
  createdBy?: string;
  createdDate?: string;
  entryDate?: string;
  entryTime?: string;
  exitDate?: string;
  exitTime?: string;
  externalNumber?: string;
  flowers?: ArticleDto[];
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idService?: number;
  /** @format int32 */
  idVersion?: number;
  location?: string;
  music?: ArticleDto;
  obituariesReminders?: ArticleDto[];
  pressObituaries?: ArticleDto[];
  serviceType?: VigilServiceDtoResServiceType;
  updatedBy?: string;
  updatedDate?: string;
  vigilAtHome?: boolean;
  vigilComplements?: ArticleDto[];
  vigilPlace?: string;
  vigilRoom?: string;
}

/** WorkOrderCard */
export interface WorkOrderCard {
  assignedUsers?: SimpleUserDto[];
  canProvideQr?: boolean;
  createdDate?: string;
  deceasedFirstSurname?: string;
  deceasedName?: string;
  deceasedSecondSurname?: string;
  destinationCenter?: string;
  /** @format int32 */
  erpId?: number;
  finishDate?: string;
  /** @format int32 */
  id?: number;
  priority?: WorkOrderCardPriority;
  startDate?: string;
  status?: WorkOrderCardStatus;
  type?: WorkOrderCardType;
}

/** WorkOrderDto */
export interface WorkOrderDto {
  /** @uniqueItems true */
  assignedUsers?: SimpleUserDto[];
  coordinatorComment?: string;
  createdBy?: string;
  createdDate?: string;
  deceasedFirstSurname?: string;
  deceasedName?: string;
  deceasedSecondSurname?: string;
  description?: string;
  driver?: string;
  dueDate?: string;
  /** @format int32 */
  erpId?: number;
  /** @format int32 */
  estimatedTime?: number;
  finishDate?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  infoQr?: InfoQr;
  operatorComment?: string;
  priority?: WorkOrderDtoPriority;
  /** @format int32 */
  recordId?: number;
  startDate?: string;
  status?: WorkOrderDtoStatus;
  type?: WorkOrderDtoType;
  updatedBy?: string;
  updatedDate?: string;
  vehicle?: string;
}

/** WorkOrderRequest */
export interface WorkOrderRequest {
  /** @format int32 */
  idRecord?: number;
}

/** WorkOrderResponse */
export interface WorkOrderResponse {
  completed?: WorkOrderCard[];
  inProgress?: WorkOrderCard[];
  pending?: WorkOrderCard[];
  simpleRecordDto?: SimpleRecordDto;
}

/** WorkOrderUpdateRequest */
export interface WorkOrderUpdateRequest {
  changeState?: boolean;
  /** @format int32 */
  id?: number;
  info?: Info;
  status?: string;
  type?: string;
}

/** WorkOrderUserRequest */
export interface WorkOrderUserRequest {
  coordinatorComment?: string;
  driver?: string;
  userIds?: number[];
  vehicle?: string;
  /** @format int32 */
  workOrderId?: number;
}

/** WorkshopDto */
export interface WorkshopDto {
  code?: string;
  createdBy?: string;
  createdDate?: string;
  /** @format int32 */
  id?: number;
  /** @format int32 */
  idVersion?: number;
  name?: string;
  updatedBy?: string;
  updatedDate?: string;
}

export enum ArticleInfoResponseDtoChannel {
  EXTERNAL = "EXTERNAL",
  PROCESSING = "PROCESSING",
  RECEPTION = "RECEPTION",
  WEB = "WEB",
}

export enum ArticleInfoResponseDtoStatus {
  CANCELLED = "CANCELLED",
  FINISHED = "FINISHED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  RECEIVED = "RECEIVED",
}

export enum ArticleStatusRequestDtoEvent {
  ACCEPT = "ACCEPT",
  CANCEL = "CANCEL",
  DELETE = 'DELETE',
  DISAGREE = "DISAGREE",
  MODIFY = 'MODIFY',
  QR_SCAN = "QR_SCAN",
}

export enum ArticleTrackingDtoChannel {
  EXTERNAL = "EXTERNAL",
  PROCESSING = "PROCESSING",
  RECEPTION = "RECEPTION",
  WEB = "WEB",
}

export enum ArticleTrackingDtoStatus {
  CANCELLED = "CANCELLED",
  DRAFT = 'DRAFT',
  FINISHED = "FINISHED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  RECEIVED = "RECEIVED",
}

export enum ArticleTrackingFilterChannel {
  EXTERNAL = "EXTERNAL",
  PROCESSING = "PROCESSING",
  RECEPTION = "RECEPTION",
  WEB = "WEB",
}

export enum ArticleTrackingFilterStatus {
  CANCELLED = "CANCELLED",
  DRAFT = 'DRAFT',
  FINISHED = "FINISHED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  RECEIVED = "RECEIVED",
}

export enum BaseWorkOrderPriority {
  HIGH = "HIGH",
  NORMAL = "NORMAL",
  URGENT = "URGENT",
}

export enum BaseWorkOrderType {
  CEREMONIA = "CEREMONIA",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  LAPIDA = "LAPIDA",
  MANUAL_CEMENTERIO = "MANUAL_CEMENTERIO",
  MANUAL_TALLER = "MANUAL_TALLER",
  MOVIMIENTO = "MOVIMIENTO",
  MOVIMIENTO_INTERNO = "MOVIMIENTO_INTERNO",
  PRACTICA_SANITARIA = "PRACTICA_SANITARIA",
  PREPARACION = "PREPARACION",
  PREPARACION_NICHO = "PREPARACION_NICHO",
  RECEPCION = "RECEPCION",
  RECOGIDA = "RECOGIDA",
  TRASLADO = "TRASLADO",
  TRASLADO_RESTOS = "TRASLADO_RESTOS",
}

export enum CeremonyServiceDtoReqServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum CeremonyServiceDtoResServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum CreateHandmadeWorkOrderDtoPriority {
  HIGH = "HIGH",
  NORMAL = "NORMAL",
  URGENT = "URGENT",
}

export enum CreateWorkOrderRequestType {
  CEREMONIA = "CEREMONIA",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  LAPIDA = "LAPIDA",
  MANUAL_CEMENTERIO = "MANUAL_CEMENTERIO",
  MANUAL_TALLER = "MANUAL_TALLER",
  MOVIMIENTO = "MOVIMIENTO",
  MOVIMIENTO_INTERNO = "MOVIMIENTO_INTERNO",
  PRACTICA_SANITARIA = "PRACTICA_SANITARIA",
  PREPARACION = "PREPARACION",
  PREPARACION_NICHO = "PREPARACION_NICHO",
  RECEPCION = "RECEPCION",
  RECOGIDA = "RECOGIDA",
  TRASLADO = "TRASLADO",
  TRASLADO_RESTOS = "TRASLADO_RESTOS",
}

export enum CremationServiceDtoReqServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum CremationServiceDtoResServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum DepositServiceDtoReqServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum DepositServiceDtoResServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum DisagreementRequestDtoType {
  DEDICATORY = "DEDICATORY",
  DELIVERY = "DELIVERY",
  ERROR = "ERROR",
  STATUS = "STATUS",
}

export enum DisagreementTypeResponseDtoTypes {
  DEDICATORY = "DEDICATORY",
  DELIVERY = "DELIVERY",
  ERROR = "ERROR",
  STATUS = "STATUS",
}

export enum EnquiryDtoReqStatus {
  COMPLETED = "COMPLETED",
  INTRODUCED = "INTRODUCED",
  PENDING = "PENDING",
}

export enum EnquiryDtoResStatus {
  COMPLETED = "COMPLETED",
  INTRODUCED = "INTRODUCED",
  PENDING = "PENDING",
}



export enum HandmadeWorkOrderDtoPriority {
  HIGH = "HIGH",
  NORMAL = "NORMAL",
  URGENT = "URGENT",
}

export enum HandmadeWorkOrderDtoStatus {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export enum HandmadeWorkOrderDtoType {
  CEREMONIA = "CEREMONIA",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  LAPIDA = "LAPIDA",
  MANUAL_CEMENTERIO = "MANUAL_CEMENTERIO",
  MANUAL_TALLER = "MANUAL_TALLER",
  MOVIMIENTO = "MOVIMIENTO",
  MOVIMIENTO_INTERNO = "MOVIMIENTO_INTERNO",
  PRACTICA_SANITARIA = "PRACTICA_SANITARIA",
  PREPARACION = "PREPARACION",
  PREPARACION_NICHO = "PREPARACION_NICHO",
  RECEPCION = "RECEPCION",
  RECOGIDA = "RECOGIDA",
  TRASLADO = "TRASLADO",
  TRASLADO_RESTOS = "TRASLADO_RESTOS",
}

export enum HealthResponseDatabase {
  KO = "KO",
  OK = "OK",
}

export enum IntermentServiceDtoReqServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum IntermentServiceDtoResServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum MasterServiceDtoType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum MasterServiceDtoWorkOrders {
  CEREMONIA = "CEREMONIA",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  LAPIDA = "LAPIDA",
  MANUAL_CEMENTERIO = "MANUAL_CEMENTERIO",
  MANUAL_TALLER = "MANUAL_TALLER",
  MOVIMIENTO = "MOVIMIENTO",
  MOVIMIENTO_INTERNO = "MOVIMIENTO_INTERNO",
  PRACTICA_SANITARIA = "PRACTICA_SANITARIA",
  PREPARACION = "PREPARACION",
  PREPARACION_NICHO = "PREPARACION_NICHO",
  RECEPCION = "RECEPCION",
  RECOGIDA = "RECOGIDA",
  TRASLADO = "TRASLADO",
  TRASLADO_RESTOS = "TRASLADO_RESTOS",
}

export enum MasterWorkOrderDtoService {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum MasterWorkOrderDtoType {
  CEREMONIA = "CEREMONIA",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  LAPIDA = "LAPIDA",
  MANUAL_CEMENTERIO = "MANUAL_CEMENTERIO",
  MANUAL_TALLER = "MANUAL_TALLER",
  MOVIMIENTO = "MOVIMIENTO",
  MOVIMIENTO_INTERNO = "MOVIMIENTO_INTERNO",
  PRACTICA_SANITARIA = "PRACTICA_SANITARIA",
  PREPARACION = "PREPARACION",
  PREPARACION_NICHO = "PREPARACION_NICHO",
  RECEPCION = "RECEPCION",
  RECOGIDA = "RECOGIDA",
  TRASLADO = "TRASLADO",
  TRASLADO_RESTOS = "TRASLADO_RESTOS",
}

export enum MovementServiceDtoReqServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum MovementServiceDtoReqType {
  BOX = "BOX",
  BUNK = "BUNK",
}

export enum MovementServiceDtoResServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum MovementServiceDtoResType {
  BOX = "BOX",
  BUNK = "BUNK",
}

/** Incoming channel */
export enum OrderLineChannel {
  EXTERNAL = "EXTERNAL",
  PROCESSING = "PROCESSING",
  RECEPTION = "RECEPTION",
  WEB = "WEB",
}

export enum PermissionElementAccessStatus {
  ALLOWED = "ALLOWED",
  DENIED = "DENIED",
}

export enum PreparationServiceDtoReqServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum PreparationServiceDtoResServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum ReceptionDataDtoReceptionType {
  WITHOUT_COFFIN = "WITHOUT_COFFIN",
  WITH_COFFIN = "WITH_COFFIN",
}

export enum RecordDtoReqState {
  FINISHED = "FINISHED",
  INVOICED = "INVOICED",
  INVOICED_PENDING = "INVOICED_PENDING",
  OPENED = "OPENED",
}

export enum RecordDtoResState {
  FINISHED = "FINISHED",
  INVOICED = "INVOICED",
  INVOICED_PENDING = "INVOICED_PENDING",
  OPENED = "OPENED",
}

export enum RecordFilterState {
  FINISHED = "FINISHED",
  INVOICED = "INVOICED",
  INVOICED_PENDING = "INVOICED_PENDING",
  OPENED = "OPENED",
}

export enum RecordInformationDtoPreparationStatus {
  FINISHED = "FINISHED",
  ON_GOING = "ON_GOING",
}

export enum RecordInformationDtoType {
  CREMATION = "CREMATION",
  INTERMENT = "INTERMENT",
}

export enum RecordInformationFilterType {
  APPOINTMENT = "APPOINTMENT",
  CEREMONY = "CEREMONY",
  COLLECTION = "COLLECTION",
  CREMATION = "CREMATION",
  INTERMENT = "INTERMENT",
  VIGIL = "VIGIL",
}

export enum RecordWorkOrderExcludedStatusPaginatedRequestWorkOrderExcludedStatus {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export enum ServiceDtoReqServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum ServiceDtoResServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

/** services */
export enum ServicesServices {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum SimpleUserRequestPermissionNames {
  ADVICE_ADD = "ADVICE_ADD",
  ADVICE_INFO = "ADVICE_INFO",
  ADVICE_READ = "ADVICE_READ",
  ADVICE_WRITE = "ADVICE_WRITE",
  APPOINTMENT = "APPOINTMENT",
  ARTICLE_ADD = "ARTICLE_ADD",
  ARTICLE_CANCEL = "ARTICLE_CANCEL",
  ARTICLE_DELETE = 'ARTICLE_DELETE',
  ARTICLE_DISAGREEMENT = "ARTICLE_DISAGREEMENT",
  ARTICLE_NOTIFY = "ARTICLE_NOTIFY",
  ARTICLE_QR_READ = "ARTICLE_QR_READ",
  ARTICLE_READ = "ARTICLE_READ",
  ARTICLE_SUPPLIER = "ARTICLE_SUPPLIER",
  ASHES_DELIVERY = "ASHES_DELIVERY",
  BUDGET_ARTICLE_MANAGEMENT = 'BUDGET_ARTICLE_MANAGEMENT',
  BUDGET_RULES_WRITE = 'BUDGET_RULES_WRITE',
  BUDGET_SETTINGS = 'BUDGET_SETTINGS',
  BUDGET_WRITE = 'BUDGET_WRITE',
  CLIENT_READ = "CLIENT_READ",
  CLIENT_WRITE = "CLIENT_WRITE",
  COLLECTION_NOTIFY = "COLLECTION_NOTIFY",
  DIRECT_SALE_READ = "DIRECT_SALE_READ",
  DIRECT_SALE_WRITE = "DIRECT_SALE_WRITE",
  ENQUIRY_ADD = "ENQUIRY_ADD",
  ENQUIRY_ASSIGN = "ENQUIRY_ASSIGN",
  ENQUIRY_NOTIFY = "ENQUIRY_NOTIFY",
  ENQUIRY_READ = "ENQUIRY_READ",
  ENQUIRY_WRITE = "ENQUIRY_WRITE",
  RECORD_ADD = "RECORD_ADD",
  RECORD_ADD_ENQUIRY = "RECORD_ADD_ENQUIRY",
  RECORD_ADD_NOTICE = "RECORD_ADD_NOTICE",
  RECORD_ASSIGN = "RECORD_ASSIGN",
  RECORD_DELETE = "RECORD_DELETE",
  RECORD_NOTIFY = "RECORD_NOTIFY",
  RECORD_QR_READ = "RECORD_QR_READ",
  RECORD_READ = "RECORD_READ",
  RECORD_WRITE = "RECORD_WRITE",
  SERVICE_READ = "SERVICE_READ",
  SERVICE_WRITE = "SERVICE_WRITE",
  USER_ADD = "USER_ADD",
  USER_READ = "USER_READ",
  USER_WRITE = "USER_WRITE",
  WORK_ORDER_CEMETERY_ADD = "WORK_ORDER_CEMETERY_ADD",
  WORK_ORDER_CEMETERY_READ = "WORK_ORDER_CEMETERY_READ",
  WORK_ORDER_CEMETERY_WRITE = "WORK_ORDER_CEMETERY_WRITE",
  WORK_ORDER_WORKSHOP_ADD = "WORK_ORDER_WORKSHOP_ADD",
  WORK_ORDER_WORKSHOP_READ = "WORK_ORDER_WORKSHOP_READ",
  WORK_ORDER_WORKSHOP_WRITE = "WORK_ORDER_WORKSHOP_WRITE",
}

export enum TransferServiceDtoReqServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum TransferServiceDtoResServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

/** type */
export enum TypeType {
  CEMETERY = "CEMETERY",
  WORKSHOP = "WORKSHOP",
}

export enum VigilServiceDtoReqServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum VigilServiceDtoResServiceType {
  CEREMONIA = "CEREMONIA",
  DEPOSIT = "DEPOSIT",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  MOVIMIENTO = "MOVIMIENTO",
  PREPARACION = "PREPARACION",
  TRANSPORTE = "TRANSPORTE",
  VELATORIO = "VELATORIO",
}

export enum WorkOrderCardPriority {
  HIGH = "HIGH",
  NORMAL = "NORMAL",
  URGENT = "URGENT",
}

export enum WorkOrderCardStatus {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export enum WorkOrderCardType {
  CEREMONIA = "CEREMONIA",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  LAPIDA = "LAPIDA",
  MANUAL_CEMENTERIO = "MANUAL_CEMENTERIO",
  MANUAL_TALLER = "MANUAL_TALLER",
  MOVIMIENTO = "MOVIMIENTO",
  MOVIMIENTO_INTERNO = "MOVIMIENTO_INTERNO",
  PRACTICA_SANITARIA = "PRACTICA_SANITARIA",
  PREPARACION = "PREPARACION",
  PREPARACION_NICHO = "PREPARACION_NICHO",
  RECEPCION = "RECEPCION",
  RECOGIDA = "RECOGIDA",
  TRASLADO = "TRASLADO",
  TRASLADO_RESTOS = "TRASLADO_RESTOS",
}

export enum WorkOrderDtoPriority {
  HIGH = "HIGH",
  NORMAL = "NORMAL",
  URGENT = "URGENT",
}

export enum WorkOrderDtoStatus {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export enum WorkOrderDtoType {
  CEREMONIA = "CEREMONIA",
  INCINERACION = "INCINERACION",
  INHUMACION = "INHUMACION",
  LAPIDA = "LAPIDA",
  MANUAL_CEMENTERIO = "MANUAL_CEMENTERIO",
  MANUAL_TALLER = "MANUAL_TALLER",
  MOVIMIENTO = "MOVIMIENTO",
  MOVIMIENTO_INTERNO = "MOVIMIENTO_INTERNO",
  PRACTICA_SANITARIA = "PRACTICA_SANITARIA",
  PREPARACION = "PREPARACION",
  PREPARACION_NICHO = "PREPARACION_NICHO",
  RECEPCION = "RECEPCION",
  RECOGIDA = "RECOGIDA",
  TRASLADO = "TRASLADO",
  TRASLADO_RESTOS = "TRASLADO_RESTOS",
}
